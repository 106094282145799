import React, { memo } from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const WhatsApp = ({ link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <WhatsAppIcon className="h-24 w-24 rounded-lg bg-whatsapp cursor-pointer" />
  </a>
);

export default memo(WhatsApp);
