import React from 'react';

import Phone from './blocks1/Contact/Phone';
import Email from './blocks1/Contact/Email';
import Website from './blocks1/Contact/Website';
import Location from './blocks1/Contact/Location';

import LinkedIn from './blocks1/SocialMedia/LinkedIn';
import YouTube from './blocks1/SocialMedia/YouTube';
import Twitter from './blocks1/SocialMedia/Twitter';
import Facebook from './blocks1/SocialMedia/Facebook';
import WhatsApp from './blocks1/SocialMedia/WhatsApp';

import PageContext from '../contexts/PageContext';

const BusinessCard = ({ data }) => {
  const CompanyLogo = () =>
    data.profile.companylogo !== '' && (
      <img
        className="w-auto h-32 mx-auto"
        src={data.profile.companylogo}
        alt={data.profile.firstName}
      />
    );

  return (
    <PageContext.Provider value={{ data }}>
      <div id="page" className="relative rounded">
        <div className="flex flex-col justify-center items-center bg-background">
          <a
            href={data.profile.companywebsite}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CompanyLogo />
          </a>
        </div>

        {/* https://codepen.io/harshkapadia/pen/bGWENrK */}

        <div className="h-14 w-full bg-background" />

        <div className="h-80 w-full grid place-items-center relative">
          <img
            className="w-80 h-80 rounded-full object-cover z-10"
            style={{
              borderColor: data.metadata.colors.background,
            }}
            src={data.profile.photograph}
            alt={data.profile.firstName}
          />
          <div className="h-40 w-full bg-background absolute top-0 left-0 " />
        </div>

        <div className="flex flex-col justify-center items-center mb-14">
          <h1 className="text-6xl font-semibold leading-tight text-black mt-2">
            {data.profile.firstName} {data.profile.lastName}
          </h1>
          <h1 className="text-5xl font-normal leading-tight text-black">
            {data.profile.subtitle}
          </h1>
        </div>

        <Phone link={`tel:${data.profile.phone}`} value={data.profile.phone} />

        <Email
          link={`mailto:${data.profile.email}`}
          value={data.profile.email}
        />

        <Website link={data.profile.website} value={data.profile.website} />

        <Location
          link={`http://maps.google.com/?q=${data.profile.address.line1},${data.profile.address.line2},${data.profile.address.city},${data.profile.address.pincode}`}
          value1={data.profile.address.line1}
          value2={data.profile.address.line2}
          value3={data.profile.address.city}
          value4={data.profile.address.pincode}
        />

        <div className="flex place-content-around mt-18 mb-5 ml-32 mr-32">
          {data.profile.facebook !== '' && (
            <Facebook
              link={`https://www.facebook.com/${data.profile.facebook}`}
            />
          )}

          {data.profile.twitter !== '' && (
            <Twitter link={`https://twitter.com/${data.profile.twitter}`} />
          )}

          {data.profile.whatsapp !== '' && (
            <WhatsApp link={`https://wa.me/${data.profile.whatsapp}`} />
          )}

          {data.profile.youtube !== '' && (
            <YouTube link={`${data.profile.youtube}`} />
          )}

          {data.profile.linkedin !== '' && (
            <LinkedIn link={`${data.profile.linkedin}`} />
          )}
        </div>

        <div className="mt-16 h-12 w-full bg-background" />
      </div>
    </PageContext.Provider>
  );
};

export default BusinessCard;
