import React, { memo, useContext } from 'react';
import EmailIcon from '@material-ui/icons/Email';
import PageContext from '../../../contexts/PageContext';

const Email = ({ link, value }) => {
  const { data } = useContext(PageContext);
  return (
    <div className="flex ml-36 mr-28 mt-4">
      <EmailIcon
        className="h-14 w-14 bg-background rounded-full mr-6 mt-4 mb-3 cursor-pointer"
        onClick={() => {
          window.open(`mailto:${data.profile.email}`);
        }}
      />
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <h1 className="text-5xl leading-tight text-black mt-2 mb-3 font-normal">
            {value}
          </h1>
        </a>
      ) : (
        <h1 className="text-5xl leading-tight text-black mt-2 mb-3 font-normal">
          {value}
        </h1>
      )}
    </div>
  );
};

export default memo(Email);
