import React, { memo } from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';

const Twitter = ({ link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <TwitterIcon className="h-24 w-24 rounded-lg bg-twitter cursor-pointer" />
  </a>
);

export default memo(Twitter);
