import React, { memo } from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';

const Facebook = ({ link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <FacebookIcon className="h-24 w-24 rounded-lg bg-facebook cursor-pointer" />
  </a>
);

export default memo(Facebook);
