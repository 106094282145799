import React, { memo } from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const LinkedIn = ({ link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <LinkedInIcon className="h-24 w-24 rounded-lg bg-linkedin cursor-pointer" />
  </a>
);

export default memo(LinkedIn);
