import React, { memo, useContext } from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import PageContext from '../../../contexts/PageContext';

const Phone = ({ link, value }) => {
  const { data } = useContext(PageContext);
  return (
    <div className="flex ml-36 mr-28 mt-4">
      <PhoneIcon
        className="h-14 w-14 bg-background rounded-full mr-6 mt-4 mb-3 cursor-pointer"
        onClick={() => {
          window.open(`tel:${data.profile.phone}`);
        }}
      />
      <a href={link} target="_blank" rel="noopener noreferrer">
        <h1 className="text-5xl leading-tight text-black mt-2 mb-3 font-normal">
          {value}
        </h1>
      </a>
    </div>
  );
};

export default memo(Phone);
