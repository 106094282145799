import React, { memo } from 'react';
import YouTubeIcon from '@material-ui/icons/YouTube';

const YouTube = ({ link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <YouTubeIcon className="h-24 w-24 rounded-lg bg-youtube cursor-pointer" />
  </a>
);

export default memo(YouTube);
